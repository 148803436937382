import React from 'react'
import '../styles/Footer.css'

const Footer = () => {
  return (
        <div className="footer">
          <img src="powered-by.png" alt="" />
        </div>
  )
}

export default Footer