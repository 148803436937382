import React from 'react'
import Footer from './Footer'
import Header from './Header'
import '../styles/Layout.css'

const Layout: React.FC = ({children}) => {
    return (
        <div className='main-container'>
            <Header></Header>
            {children}
            <Footer></Footer>
        </div>
    )
}

export default Layout